import { GET, POST, POSTFORM, DELETE, POSTUNTRACK, GETUNTRACK, PATCHUNTRACK } from '#api/Fetch'

import API from '#api/index'
import { navigate } from '../components/basepath'
import Cookies from 'js-cookie'

class MarketApi extends API {
  constructor() {
    super()

    this.prefix = 'pharmacy'
    //this.checkAuth();
  }

  //
  onboardMetadata(formData) {
    return POSTFORM(`${this.url}/api/customer/store`, formData, {
      Authorization: `Bearer ${this.token}`
    })
  }

  getMetadata() {
    return GET(`${this.url}/api/customer/store`, {}, { Authorization: `Bearer ${this.token}` })
  }

  // Marketplace - Product
  searchCatalogue(token =this.token, { mode=0, _page = 1, _pagesize = 20, _search, filters,locale, search_priority }) {
    return GET(
      `${this.url}/api/market/customer/catalogue`,
      { mode, _page, _pagesize, _search, filters,country:locale, search_priority },
      { Authorization: `Bearer ${token}` }
    )
  }

  getCatalogueProduct(product_id,token,locale) {
    return GET(
      `${this.url}/api/market/customer/catalogue/${product_id}?country=${locale}`,
      {},
      { Authorization: `Bearer ${token||this.token}` }
    )
  }
  

  getCataloguePackage(storeName, categoryName, slug, token,locale) {
    return GET(
      `${this.url}/api/market/customer/catalogue/package/${storeName}/${categoryName}/${slug}`,
      {country:locale},
      { Authorization: `Bearer ${token||this.token}` }
    )
  }

  getStore(store_id) {
    return GET(
      `${this.url}/api/market/customer/catalogue/store/${store_id}`,
      {},
      { Authorization: `Bearer ${this.token}` }
    )
  }

  getStoreProduct(uuid, { _page = 1, _pagesize = 20 }) {
    return GET(
      `${this.url}/api/market/customer/catalogue/store/${uuid}/product`,
      { _page, _pagesize },
      { Authorization: `Bearer ${this.token}` }
    )
  }

  addCart({ inventory_id, quantity, is_business = false, operation, coupon_id, token, product_id }) {
    token = Cookies.get('userToken')
    return POSTUNTRACK(
      `${this.url}/api/market/cart`,
      { inventory_id, quantity, is_business, operation, coupon_id, product_id },
      { Authorization: `Bearer ${this.token || token}` }
    )
  }

  selectItem({ id,selected, token }) {
    return POSTUNTRACK(
      `${this.url}/api/market/selectItem`,
      { id,selected },
      { Authorization: `Bearer ${this.token || token}` }
    )
  }

  consultPoisonVariantDetail({ poisonVariantID, token,locale }) {
    return POSTUNTRACK(
      `${this.url}/api/market/consultPoisonVariantDetail?country=${locale}`,
      { poisonVariantID },
      { Authorization: `Bearer ${this.token || token}` }
    )
  }

  updatePoisonVariantCartItem({ inventory_id, cart_id, token }) {
    return POSTUNTRACK(
      `${this.url}/api/market/updatePoisonVariantCartItem`,
      { inventory_id, cart_id},
      { Authorization: `Bearer ${this.token || token}` }
    )
  }

  packageAddCart({ package_id, quantity, token, is_business = false, operation, coupon_id }) {
    return POSTUNTRACK(
      `${this.url}/api/market/cart?mode=2`,
      { package_id, quantity, operation, coupon_id, is_business },
      { Authorization: `Bearer ${this.token || token}` }
    )
  }

  getCart({ is_business = false, _page = 1, _pagesize = 20, token,locale }) {
    let country=locale
    return GETUNTRACK(
      `${this.url}/api/market/cart`,
      { is_business, _page, _pagesize,country },
      { Authorization: `Bearer ${this.token || token}` }
    )
  }

  getServicesCart({ is_business = false, _page = 1, _pagesize = 20, token,locale }) {
    let country=locale
    return GETUNTRACK(
      `${this.url}/api/market/cart`,
      { mode: 2, is_business, _page, _pagesize,country },
      { Authorization: `Bearer ${this.token || token}` }
    )
  }

  getCartCount({ is_business = false,token }) {
    return GET(
      `${this.url}/api/market/cart/count`,
      { is_business },
      { Authorization: `Bearer ${this.token || token}` }
    )
  }

  deleteCart({id, token}) {
    return DELETE(
      `${this.url}/api/market/cart/${id}`,
      {},
      { Authorization: `Bearer ${this.token || token}` }
    )
  }

  createTransaction({formData, token}) {
    return POSTFORM(`${this.url}/api/market/transaction`, formData, {
      Authorization: `Bearer ${this.token || token}`
    })
  }

  getTransactions({ is_business = false, _page = 1, _pagesize = 20 }) {
    return GET(
      `${this.url}/api/market/transaction`,
      { is_business, _page, _pagesize },
      { Authorization: `Bearer ${this.token}` }
    )
  }

  getTransaction({ uuid, is_business = false, token,locale, checkout = false}) {
    return GET(
      `${this.url}/api/market/transaction/${uuid}`,
      { is_business,country:locale, checkout },
      { Authorization: `Bearer ${this.token || token}` }
    )
  }

  removeItemsFromTransaction({ref_id, data, token}) {
    return DELETE(
      `${this.url}/api/market/transaction/items`,
      { ref_id, data },
      { Authorization: `Bearer ${this.token || token}` }
    )
  }

  getNewProducts(token, country) {
    return GET(
      `${this.url}/api/market/customer/catalogue/new`,
      {country,non_poison_only:true,_pagesize:25,mode:1},
      { Authorization: `Bearer ${token||this.token}` }
    )
  }

  getNewProductsIndex(token, country) {
    return GET(
      `${this.url}/api/market/customer/catalogue/newproduct`,
      {country},
      { Authorization: `Bearer ${token||this.token}` }
    )
  }

  getNewPackages(token,locale,location=null) {
    return GET(
      `${this.url}/api/market/customer/catalogue/new`,
      {location,country:locale,_pagesize:25,mode:2},
      { Authorization: `Bearer ${token||this.token}` }
    )
  }

  getPersonalized(token,locale) {
    return GET(
      `${this.url}/api/market/customer/catalogue/personalized?country=${locale}`,
      {},
      { Authorization: `Bearer ${token||this.token}` }
    )
  }

  updateTransactionAddress({ ref_id, address_id, token,locale }) {
    return POST(
      `${this.url}/api/market/transaction/address?country=${locale}`,
      {
        ref_id,
        address_id, 
        is_business: false
      },
      { Authorization: `Bearer ${this.token || token}` }
    )
  }

  updateRemark({remark, token}) {
    return POST(
      `${this.url}/api/market/order/update/remark`,
      { remark },
      { Authorization: `Bearer ${this.token || token}` }
    )
  }

  updateTransactionDelivery({ ref_id, order_id, provider_id = 0, is_international=false, token,locale }) {
    return POST(
      `${this.url}/api/market/transaction/delivery?country=${locale}`,
      {
        ref_id,
        order_id,
        provider_id,
        is_international
      },
      { Authorization: `Bearer ${this.token || token}` }
    )
  }

  getPricelist({ ref_id, order_id}) {
    return POST(
      `${this.url}/api/market/delivery/pricelist`,
      {
        ref_id,
        order_id
      },
      { Authorization: `Bearer ${this.token}` }
    )
  }

  checkout({ ref_id, address_id, redirect_uri, appliedPlatformCoupon, totalConsultPrice, token }) {
    return POST(
      `${this.url}/api/market/transaction/checkout`,
      {
        ref_id,
        redirect_uri,
        appliedPlatformCoupon,
        totalConsultPrice,
        address_id
      },
      { Authorization: `Bearer ${this.token || token}` }
    )
  }

  getAddress({userToken,isList}) {
    return GET(`${this.url}/api/customer/address`, {isList}, { Authorization: `Bearer ${userToken}` })
  }

  newAddress({
    address,
    token
  }) {

    const{
      name,
      email,
      phone,
      address_1,
      country,
      state,
      city,
      postcode,
      defaultAddress,
      same_as_billing,
      verify,
      country_code,
      bill_name,
      bill_email,
      bill_phone,
      bill_address_1,
      bill_country,
      bill_state,
      bill_city,
      bill_postcode,
      previous_address_id
    }=address

    return POST(
      `${this.url}/api/customer/address`,
      {
        name,
        email,
        phone,
        address_1,
        country,
        state,
        city,
        postcode,
        defaultAddress,
        same_as_billing,
        verify,
        country_code,
        bill_name,
        bill_email,
        bill_phone,
        bill_address_1,
        bill_country,
        bill_state,
        bill_city,
        bill_postcode,
        previous_address_id
      },
      { Authorization: `Bearer ${this.token || token}` }
    )
  }

  updateAddress({
    id,
    name,
    email,
    phone,
    address_1,
    country,
    state,
    city,
    postcode,
    defaultAddress,
    country_code,
    same_as_billing,
    verify,
    bill_name,
    bill_email,
    bill_phone,
    bill_address_1,
    bill_country,
    bill_state,
    bill_city,
    bill_postcode,
    last_selected,
    token
  }) {
    return POST(
      `${this.url}/api/customer/address/update`,
      {
        address_id: id,
        name,
        email,
        phone,
        address_1,
        country,
        state,
        city,
        postcode,
        defaultAddress,
        country_code,
        same_as_billing,
        verify,
        bill_name,
        bill_email,
        bill_phone,
        bill_address_1,
        bill_country,
        bill_state,
        bill_city,
        bill_postcode,
        last_selected
      },
      { Authorization: `Bearer ${this.token || token}` }
    )
  }

  selectAddress({id, token}) {
    return PATCHUNTRACK(
      `${this.url}/api/customer/address/select`,
      {id},
      { Authorization: `Bearer ${this.token || token}` }
    )
  }

  deleteAddress({id, token}) {
    return POST(
      `${this.url}/api/customer/address/delete`,
      {id},
      { Authorization: `Bearer ${this.token || token}` }
    )
  }

  // Marketplace Orders
  listMarketOrderByStatus({ order_status = 0, is_business = false, _page = 1, _pagesize = 20 }) {
    return GET(
      `${this.url}/api/market/order/status/${order_status}`,
      { is_business, _page, _pagesize },
      { Authorization: `Bearer ${this.token}` }
    )
  }

  cancelOrder(order_id) {
    return POST(
      `${this.url}/api/market/order/cancel/${order_id}`,
      {},
      { Authorization: `Bearer ${this.token}` }
    )
  }

  receivedOrder(order_id) {
    return POST(
      `${this.url}/api/market/order/receive/${order_id}`,
      {},
      { Authorization: `Bearer ${this.token}` }
    )
  }

  archivedOrder(order_id) {
    return POST(
      `${this.url}/api/market/order/archive/${order_id}`,
      {},
      { Authorization: `Bearer ${this.token}` }
    )
  }

  triggerConsult(userToken,cartDetails,consultPrice,platform,locale) {
    return POST(
      `${this.url}/api/teleconsult/trigger`,
      {cartDetails,platform,consultPrice,locale},
      { Authorization: `Bearer ${userToken}` }
    )
  }

  // Coupon
  getCoupons() {
    return GET(`${this.url}/api/market/coupon`, {}, { Authorization: `Bearer ${this.token}` })
  }

  getCouponsByStoreId({store_id, token, locale}) {
    let country = locale
    return GET(
      `${this.url}/api/market/coupon/${store_id}`,
      {country},
      { Authorization: `Bearer ${this.token || token}` }
    )
  }

  getPlatformCoupon({token, locale, visibility}) {
    let country = locale
    return GET(
      `${this.url}/api/market/platformcoupon`,
      {country, visibility},
      { Authorization: `Bearer ${this.token || token}` },
    )
  }

  applyCoupon({coupon_code, cartArray, coupon_type, token,uuid,locale}) {
    return POST(
      `${this.url}/api/market/coupon/apply?country=${locale}`,
      { coupon_code, cartArray, coupon_type, uuid},
      { Authorization: `Bearer ${this.token || token}` }
    )
  }

  updateTransaction({uuid, coupon, token}) {
    return POST(
      `${this.url}/api/market/transaction/update`,
      { uuid, coupon },
      { Authorization: `Bearer ${this.token || token}` }
    )
  }

  suggestCoupon({cartsIds = [], token}) {
    return POSTUNTRACK(
      `${this.url}/api/market/coupon/suggestion`,
      { cartsIds },
      { Authorization: `Bearer ${this.token || token}` }
    )
  }

  getProductCategories() {
    return GETUNTRACK(`${this.url}/api/market/b2c/categories`, { store_type: 1, country_code: 'my' }, {Authorization: `Bearer ${this.token}`, 'Cache-Control': 'force-cache'})
  }

  getStoreProductData({store, token, type, page=1, pageSize,category,subcategory,locale }) {
    return GETUNTRACK(`${this.url}/api/market/store/product/${store}`,
      {subcategory,category,type, page, pageSize,country:locale},
      { Authorization: `Bearer ${ token || this.token }` }
    )
  }

  getStoreData({store, token,locale}) {
    return GETUNTRACK(`${this.url}/api/market/store/${store}`,
      {store,country:locale},
      { Authorization: `Bearer ${ token || this.token }` }
    )
  }

  getStoresData({store, token,location}) {
    return GETUNTRACK(`${this.url}/api/market/stores`,
      {location},
      { Authorization: `Bearer ${ token || this.token }` }
    )
  }

  getFeaturedProductCategories(token) {
    return GETUNTRACK(`${this.url}/api/market/categories/featured`,
      { store_type: 1, country_code: 'my' },
      { Authorization: `Bearer ${token||this.token}` }
    )
  }

  getPackageCategories({location}) {
    return GETUNTRACK(`${this.url}/api/market/b2c/packages/categories`, { store_type: 1, country_code: 'my',location }, {'Cache-Control': 'force-cache'})
  }

  getPackageCollections({token,locale,type,carousel=false, category=null}) {
    return GETUNTRACK(`${this.url}/api/market/customer/collections`, 
      {country:locale,type,carousel, category}, 
      { Authorization: `Bearer ${token||this.token}` }
    )
  }

  getProductsIDSiteMap() {
    return GETUNTRACK(`${this.url}/api/market/productsidsitemap`,
      {},
      {}
    )
  }

  getProductsSGSiteMap() {
    return GETUNTRACK(`${this.url}/api/market/productssgsitemap`,
      {},
      {}
    )
  }

  getSaltsSiteMap({ country }) {
    return GETUNTRACK(`${this.url}/api/market/getsaltssitemap`,
      {country},
      {}
    )
  }


  getProductsCategorySiteMap({ country }) {
    return GETUNTRACK(`${this.url}/api/market/productscategorysitemap`,
      {country},
      {}
    )
  }

  getProductsSiteMap({ country }) {
    return GETUNTRACK(`${this.url}/api/market/productssitemap`,
      {country},
      {}
    )
  }

  getProductsCategoryIDSiteMap() {
    return GETUNTRACK(`${this.url}/api/market/productscategoryidsitemap`,
      {},
      {}
    )
  }

  getProductsCategorySGSiteMap() {
    return GETUNTRACK(`${this.url}/api/market/productscategorysgsitemap`,
      {},
      {}
    )
  }

  getStoresSiteMap() {
    return GETUNTRACK(`${this.url}/api/market/storesSiteMap`,
      {},
      {}
    )
  }

  getHealthServicesIDSiteMap() {
    return GETUNTRACK(`${this.url}/api/market/packagesidsitemap`,
      {},
      {}
    )
  }

  getPackagesCategorySiteMap() {
    return GETUNTRACK(`${this.url}/api/market/packagescategorysitemap`,
      {},
      {}
    )
  }

  redirectToAppStore() {
    let userAgent = navigator.userAgent || navigator.vendor || window.opera;
    let appStoreUrl = 'https://play.google.com/store/apps/details?id=com.docmobile&hl=en&gl=US';

    // if androind or windows pc redirect to android play store
    if (/android|Win/i.test(userAgent)) {
      appStoreUrl = 'https://play.google.com/store/apps/details?id=com.docmobile&hl=en&gl=US';
    } else if (/iPad|iPhone|iPod|Mac/.test(userAgent) && !window.MSStream) {
      // if ios redirect to app store
      appStoreUrl = 'https://apps.apple.com/kh/app/doctoroncall-doc/id1549810871';
    }

    navigate.push(appStoreUrl);
  }

  getCategoryProducts({slug, page, pagesize, country, filters}) {
    return GET(`${this.url}/api/market/b2c/categories/${slug}/products`,
      { page, pagesize, country, filters},
      { Authorization: `Bearer ${this.token}` }
    )
  }
  getCategoryServices({slug, page, pagesize, country, filters,location}) {
    return GET(`${this.url}/api/market/b2c/categories/${slug}/packages`,
      { page, pagesize, country, filters,location},
      { Authorization: `Bearer ${this.token}` }
    )
  }

  getCategoryDetails({slug}) {
    return GET(`${this.url}/api/market/b2c/categories/${slug}`,
      {},
      { Authorization: `Bearer ${this.token}` }
    )
  }

  getRelatedItems({is_service, category_id, locale, salt_id}){
    return GET(`${this.url}/api/market/b2c/categories/related-items`, {is_service, category_id, country: locale, salt_id}, { Authorization: `Bearer ${this.token}` })
  }
  getSalt({salt, country}){
    return GET(`${this.url}/api/market/b2c/salts/${salt}`, {country}, { Authorization: `Bearer ${this.token}` })
  }

  getAllSalts({country}){
    return GET(`${this.url}/api/market/b2c/salts`, {country}, { Authorization: `Bearer ${this.token}` })
  }

  getCondtion({condition, country}){
    return GET(`${this.url}/api/market/b2c/conditions/${condition}`, {country}, { Authorization: `Bearer ${this.token}` })
  }

  getAllConditions({country}){
    return GET(`${this.url}/api/market/b2c/conditions`, {country}, { Authorization: `Bearer ${this.token}` })
  }

  getPaymentSettings({country}){
    return GET(`${this.url}/api/market/b2c/platform/payment`, {country})
  }

  postContactForm(payload){
    return POSTUNTRACK(
      `${this.url}/api/market/b2c/contact-us`,
      { ...payload },
      { Authorization: `Bearer ${this.token}` }
    )
  }
  getSeoMetadata(page, locale){
    return GET(`${this.url}/api/market/seo-metadata/${page}`, {locale}, { Authorization: `Bearer ${this.token}` })
  }

  getDeliveryShippingRules(userToken, store_id, locale, tags) {
    let productTagsStringify = JSON.stringify(tags)
    return GET(`${this.url}/api/market/delivery/shippingrules`,
      {store_id, country:locale, product_tags: productTagsStringify }, 
      { Authorization: `Bearer ${userToken||this.token}` 
    })
  }
}

let Api = new MarketApi()
export default Api
